import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "recaptchaResponse", "statusMessage"]

  connect() {
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        this.formTarget.addEventListener('submit', this.handleSubmit.bind(this))
      })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    const siteKey = this.element.dataset.recaptchaSiteKeyValue
    grecaptcha.execute(siteKey, { action: 'newsletter_signup' }).then((token) => {
      if (token) {
        this.recaptchaResponseTarget.value = token
        this.formTarget.submit()
      } else {
        this.showStatusMessage("validation failed. Please try again.", true)
      }
    }).catch((error) => {
      console.error("validation execution error:", error)
      this.showStatusMessage("An error occurred during validation. Please try again.", true)
    })
  }

  showStatusMessage(message, isError = false) {
    this.statusMessageTarget.textContent = message
    this.statusMessageTarget.classList.toggle('text-red-500', isError)
    this.statusMessageTarget.classList.toggle('text-green-500', !isError)
  }
}
